import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Strong, LinkBox, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Услуги | Предложение Zayats Studio
			</title>
			<meta name={"description"} content={"Превратите свое видение в искусство"} />
			<meta property={"og:title"} content={"Услуги | Предложение Zayats Studio"} />
			<meta property={"og:description"} content={"Превратите свое видение в искусство"} />
			<meta property={"og:image"} content={"https://venturixpro.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://venturixpro.com/img/3997786.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://venturixpro.com/img/3997786.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://venturixpro.com/img/3997786.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://venturixpro.com/img/3997786.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://venturixpro.com/img/3997786.png"} />
			<meta name={"msapplication-TileImage"} content={"https://venturixpro.com/img/3997786.png"} />
			
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0 80px 0"
			lg-padding="60px 0 60px 0"
			md-padding="40px 0 40px 0"
			sm-padding="30px 0 30px 0"
			background="--color-darkL2"
		>
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--light"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наши фотоуслуги
				</Text>
				<Text font="--lead" color="--light" max-width="900px">
				В студии Zayats мы предлагаем набор специализированных фотоуслуг, отвечающих самым разным потребностям наших клиентов. Независимо от того, хотите ли вы запечатлеть драгоценные вехи жизни или разработать уникальную рекламную кампанию, наши услуги призваны воплотить ваши творческие замыслы в жизнь с точностью и мастерством.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://venturixpro.com/img/7.jpg"
						border-radius="24px"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						display="block"
						position="static"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Портретная фотосъемка
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							<Strong>
							Индивидуальные и семейные фотографии: 
							</Strong>
							{" "}Снимайте нестареющие портреты, уделяя внимание освещению, заднику и позам.
							<br />
							<Strong>
							Профессиональные снимки головы: {" "}
							</Strong>
							Идеально подходит для предпринимателей, актеров и профессионалов, улучшая ваше портфолио за счет полированного вида.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Событийная фотография
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							<Strong>
							Свадьбы и торжества: 
							</Strong>
							{" "}Сохраните радость и великолепие ваших особых дней с помощью ярких, полных эмоций снимков.
							<br />
							<br />
							<Strong>
							Корпоративные мероприятия: 
							</Strong>
							{" "}Профессионально документируйте важные корпоративные собрания, конференции и гала-концерты.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://venturixpro.com/img/8.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://venturixpro.com/img/9.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Коммерческая фотосъемка
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							<Strong>
							Фотография продукции: 
							</Strong>
							{" "}Демонстрируйте свои товары с помощью изображений высокого разрешения, подчеркивающих каждую деталь.
							<br />
							<br />
							<Strong>
							Модная фотография: 
							</Strong>
							{" "}Оживите свои модные проекты динамичными и стильными снимками, которые завораживают аудиторию.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
						И это лишь малая часть того, что мы можем предложить! Посетите нас, чтобы открыть для себя весь спектр наших фотоуслуг. Нужны ли вам интимные портреты или масштабное освещение мероприятия, студия Zayats готова превзойти ваши ожидания.
						</Text>
						<Box
							min-width="100px"
							min-height="100px"
							margin="20px 0px 0px 0px"
							text-align="center"
							padding="20px 0px 0px 0px"
						>
							<Link
								href="/contacts"
								color="#000000"
								padding="8px 24px 8px 024px"
								background="--color-orange"
								text-decoration-line="initial"
								font="--lead"
								margin="0px 0px 0px 0px"
								border-radius="8px"
							>
								Связаться с нами
							</Link>
						</Box>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://venturixpro.com/img/10.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});